@import '../variables'

#freelancer-signup
  display: grid
  grid-template-columns: 1fr
  gap: 20px

  > .social
    background-color: #eeeeee
    border-radius: 10px
    padding: 10px
    display: none
    justify-content: space-evenly
    grid-row: 5
    gap: 15px

    > button
      background-color: $color-white
      color: $color-black
      border-radius: 20px
      border: 1px solid #dadce0
      padding: 10px
      display: flex
      align-items: center

      > .gr-icon
        font-size: 20px

      > p
        display: none

      &:hover
        border-color: #d2e3fc
        background-color: #f7faff

  &.final-state
    height: 100%
    padding-top: 50px
    align-content: center
    justify-items: center

    > p
      text-align: center

  #resume-link
    display: flex
    align-items: center

    &:before
      color: $color-blue
      display: inline-block
      font-size: 40px
      margin-right: 10px

  #resume-current
    margin-top: 10px
    font-weight: $font-weight-500
    font-size: $font-size-15
    line-height: 30px

    > .attachment
      padding: 5px 20px
      box-shadow: $box-shadow
      display: inline-flex
      align-items: center
      border-radius: 5px
      margin-left: 20px
      font-weight: $font-weight-300
      font-size: $font-size-13
      line-height: 20px
      white-space: nowrap

      > img
        height: 20px
        margin-right: 15px

      > .name
        max-width: 100px
        text-overflow: ellipsis
        overflow: hidden
        white-space: nowrap
        margin-right: 10px

  > nav
    display: flex
    flex-wrap: wrap
    gap: 20px

@media only screen and (min-width: $breakpoint-tablet)
  #freelancer-signup
    grid-template-columns: 1fr 310px

    > *
      grid-column: 1 / span 2

    // > .firstName-container, > .lastName-container, > .email-container
    //  grid-column: 1
    > .phone-wrapper
      & .choices__list--dropdown.is-active
        width: 400px !important

    > .social
      grid-column: 2
      grid-row: 5 / span 3
      flex-direction: column
      border-radius: 0 10px 10px 0
      padding: 20px 30px
      margin-top: 24px

      > button
        border-radius: 4px
        padding: 6px 10px

        > .gr-icon
          margin-right: 7px

        > p
          display: inline-block
          color: #3c4043
          font-size: $font-size-14
          font-weight: $font-weight-400
          flex-grow: 1
          justify-self: center

    #resume-current > .attachment > .name
      max-width: 200px