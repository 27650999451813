@import '../variables'

#testemonials
  background-color: $color-pale-gray

  > h2, > h1.medium
    text-align: center
    padding-bottom: 30px

  .splide__slide
    padding: 8px

  .testemonial
    min-height: 280px
    display: grid
    grid-template-columns: auto 1fr
    grid-template-rows: 20px auto 15px auto
    //column-gap: 20px
    box-shadow: $box-shadow
    background-color: $color-white
    border-radius: 25px
    padding: 20px

    p.small
      color: $color-dark-gray

    > .type
      grid-column: 2
      justify-self: right

    > .name-position
      grid-column: 1 / span 2
      align-self: center
      margin-top: 20px

    > .highlight
      grid-row: 3
      width: 80px
      height: 3.75px
      
    > p
      grid-column: 1 / span 2
      grid-row: 5

    &.company
      > .type
        color: $color-purple

      > .highlight
        background-color: $color-purple

    &.freelancer
      > .type
        color: $color-green

      > .highlight
        background-color: $color-green

@media only screen and (min-width: $breakpoint-small-desktop)
  #testemonials
    > h2, > h1.medium
      padding-bottom: 60px

    .splide__slide
      padding: 8px

    .testemonial
      min-height: 320px
      padding: 25px
      grid-template-rows: 20px 90px 15px auto auto

      //> img
      //  grid-column: 1
      //  grid-row: 1 / span 2

      > .type
        grid-column: 2
        grid-row: 1

      > .name-position
        grid-column: 2
        grid-row: 2
        margin-top: 0