#company-signup
  display: grid
  grid-template-columns: 1fr
  gap: 20px

  &.final-state
    height: 100%
    padding-top: 50px
    align-content: center
    justify-items: center

    > p
      text-align: center

  > nav
    display: flex
    flex-wrap: wrap
    gap: 20px