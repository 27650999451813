@import '../variables'

.benefits
  background-color: $color-pale-gray
  padding-bottom: 35px

  &.company > p
    color: $color-purple

  &.freelancer > p
    color: $color-green

  > p
    padding: 30px $padding-side-mobile 0 $padding-side-mobile

  > h2, > h1.medium
    margin-top: 0
    padding: 0 $padding-side-mobile

  > .splide
    .slide-page
      display: flex
      flex-direction: column-reverse
      height: 100%

      > .description
        background-color: $color-white
        margin: -30px $padding-side-mobile 0 $padding-side-mobile
        padding: 30px 15px
        flex-grow: 1

        > button
          display: block
          margin: 50px auto 0 auto

      > .slide-image
        background-size: cover
        background-position: center
        background-repeat: no-repeat
        width: 100%
        height: 0
        padding-top: 77%

@media only screen and (min-width: $breakpoint-small-desktop)
  .benefits
    padding: 0 $padding-side-small-desktop 70px $padding-side-small-desktop

    &.freelancer > .splide .slide-page
      flex-direction: row-reverse

    > p
      padding: 104px 0 0 0

    > h2, > h1.medium
      max-width: 900px
      padding: 0

    > .splide
      .slide-page
        display: flex
        flex-direction: row

        > .description
          padding: 75px 60px
          margin: 0
          width: 50%

          > button
            display: inline-block
            margin: 50px 0 0 0

        > .slide-image
          height: unset
          width: 50%
          padding-top: 0

@media only screen and (min-width: $breakpoint-desktop)
  .benefits
    padding: 0 $padding-side-desktop 70px $padding-side-desktop