@import '../variables'
@import '@splidejs/splide/css'

.gigs-slider

  .splide__pagination
    bottom: 0

    .splide__pagination__page
      height: 14px
      width: 14px
      border: 2px solid transparent
      background: transparent

      &.is-active
        transform: none
        opacity: 1

  .splide__slide
    padding: 0 1px

  .splide__arrow
    background-color: $color-white-80
    opacity: 1
    transition: opacity 0.5s linear

    &:disabled
      opacity: 0
      cursor: default

    &--prev
      padding-right: 3px

    &--next
      padding-left: 2px

    > svg
      display: none

    &:before
      color: $color-dark-gray
      font-size: $font-size-16

    &:hover
      background-color: $color-white

      &:before
        color: $color-black

  &.company, &.freelancer
    padding: 0 0 50px 0

    .splide__arrow--prev
      left: 4px

    .splide__arrow--next
      right: 4px

  &.company
    .splide__pagination__page
      border-color: $color-purple

      &.is-active
        background: $color-purple

  &.freelancer
    .splide__pagination__page
      border-color: $color-green

      &.is-active
        background: $color-green

  &.plain
    padding: 0 16px

    .splide__arrow
      background: none

      &--prev
        margin-left: -20px

      &--next
        margin-right: -20px

    .splide__pagination
      display: none

@media only screen and (min-width: $breakpoint-small-desktop)
  .gigs-slider
    .splide__arrow:before
      font-size: $font-size-25

    &.company, &.freelancer
      padding: 50px

      .splide__arrow
        background: none

    &.plain
      padding: 0 50px
      width: auto

      .splide__arrow

        &--prev
          margin-left: 0

        &--next
          margin-right: 0

