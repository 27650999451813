@import '../partials/find'
// @import '../partials/related_companies'
@import '../partials/freelancer_categories'
@import '../partials/benefits'
@import '../partials/social_responsibility'
@import '../partials/home_related'
@import '../partials/testemonials'

main
  > #social-responsibility > .image
    background-image: url('./home_banner_s5_700.jpg')

  > .benefits
    &.company
      .slide-1-img
        background-image: url(./home_c_companies_s3_01_700.jpg)
      .slide-2-img
        background-image: url(./home_c_companies_s3_02_700.jpg)
        background-position-x: 80% !important
      .slide-3-img
        background-image: url(./home_c_companies_s3_03_700.jpg)
    &.freelancer
      .slide-1-img
        background-image: url(./home_c_freelancers_s4_01_700.jpg)
        background-position-x: 20% !important
      .slide-2-img
        background-image: url(./home_c_freelancers_s4_02_700.jpg)
      .slide-3-img
        background-image: url(./home_c_freelancers_s4_03_700.jpg)
        background-position-x: 70% !important

@media only screen and (min-width: $breakpoint-tablet)
  main
    > #social-responsibility > .image
      background-image: url('./home_banner_s5.jpg')

    > .benefits
      &.company
        .slide-1-img
          background-image: url(./home_c_companies_s3_01.jpg)
        .slide-2-img
          background-image: url(./home_c_companies_s3_02.jpg)
        .slide-3-img
          background-image: url(./home_c_companies_s3_03.jpg)
      &.freelancer
        .slide-1-img
          background-image: url(./home_c_freelancers_s4_01.jpg)
        .slide-2-img
          background-image: url(./home_c_freelancers_s4_02.jpg)
        .slide-3-img
          background-image: url(./home_c_freelancers_s4_03.jpg)