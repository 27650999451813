@import '../variables'
@import 'choices.js/src/styles/choices.scss'

div.choices, div.choices[data-type*=select-one]
  margin-bottom: 0

  > div.choices__inner
    border-radius: 10px
    padding: 15px 20px
    border: 2px solid #e7e7e7
    font-size: $font-size-15
    line-height: $font-size-24
    background-color: unset

    > div.choices__list--single, > div.choices__list
      padding: 0

    > div.choices__list--multiple > div.choices__item
      background-color: $color-purple
      border-color: $color-purple-hover

      > .choices__button
        border-color: $color-white

    > input.choices__input
      background-color: unset

  > div.choices__list
    border-radius: 10px
    border: 2px solid #e7e7e7

    > input.choices__input
      padding-left: 20px

    > div.choices__list > div.choices__item
      padding-left: 20px

      &.choices__placeholder
        display: none

  > div.is-active
    position: relative