@import '../variables'

#find-remote
  background-image: url('./home_head_banner_s1.jpg')
  background-repeat: no-repeat
  background-position: 70% top
  background-size: 150%
  padding: 200px $padding-side-mobile 0 $padding-side-mobile
  display: flex
  flex-direction: column
  align-items: center
  gap: 15px

  > .block
    width: 100%
    max-width: 320px
    min-width: 300px
    color: $color-white
    padding: 25px 15px 10px 30px
    cursor: pointer
    position: relative
    transition: background-color 0.5s linear

    > .normal
      max-width: 250px

    &#find-remote-work
      background-color: $color-green
      border-radius: 0 0 0 25px

      &:hover
        background-color: $color-green-hover

    &#find-remote-talent
      background-color: $color-purple
      border-radius: 0 25px 0 0

      &:hover
        background-color: $color-purple-hover

    > h3
      color: $color-white
      font-size: 30px

    > p
      margin: 20px 5px

    > .call-to-action
      display: flex
      align-items: center
      position: absolute
      right: 15px
      bottom: 15px

      > h5
        display: none

      > .gr-icon
        font-size: 30px
        margin-left: 10px

    &:hover, &.run-animation
      > .call-to-action
        animation: slide 0.5s forwards
     
@media only screen and (min-width: $breakpoint-tablet)
  #find-remote
    background-size: unset
    flex-direction: row
    justify-content: space-evenly
    align-items: flex-end

    > .block
      height: 320px
      padding: 80px 30px 30px 30px

      > h3
        font-size: 38px

      > .call-to-action > h5
        display: inline-block
        font-size: $font-size-16
        color: $color-white

@media only screen and (min-width: $breakpoint-small-desktop)
  #find-remote
    background-position: top right
    height: 670px
    padding: 0 $padding-side-small-desktop
    align-items: center
    justify-content: flex-start

@media only screen and (min-width: $breakpoint-desktop)
  #find-remote
    padding: 0
    margin: 0 $padding-side-desktop
