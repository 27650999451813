@import '../variables'

#social-responsibility
  display: grid
  grid-template-columns: 1fr
  grid-template-rows: auto 100vw auto auto
  gap: 20px

  > h2, > h1.medium
    text-align: center

  > a
    justify-self: center

  > .image
    background-repeat: no-repeat
    background-position: center
    background-size: cover

@media only screen and (min-width: $breakpoint-small-desktop)
  #social-responsibility
    grid-template-columns: 1fr 2fr
    grid-template-rows: auto auto auto
    gap: 80px

    > h2, > h1.medium
      text-align: left

    > a
      justify-self: left

    > h2, > h1.medium, > p, > a
      grid-column: 1

    > .image
      grid-column: 2
      grid-row: 1 / span 3
