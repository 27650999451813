@import '../variables'

#freelancer-categories

  &.compact
    padding-top: 0
    padding-bottom: 0

  &.extended
    background-image: url('./home_background_s2_700.jpg')
    background-repeat: no-repeat
    background-position: 70% 50%
    background-size: cover
    margin: 0

    > h2, > h1.medium
      text-align: center
      padding-bottom: 25px

  > .categories-container

    > .role
      padding: 8px 8px 8px 40px

      > .underlined

        &:not(.active)
          &:after
            display: block
            content: ''
            height: 1px
            background: $color-black

          &:hover > h5
            color: $color-blue
            padding-left: 10px
            padding-right: 0

        > h5
          padding-left: 0
          padding-right: 10px
          transition: all 0.5s

    > .positions
      display: none
      opacity: 0
      transition: opacity 0.5s

      &.active
        display: block
        opacity: 1

      .slide
        padding: 25px
        margin: 16px 8px
        background-color: $color-white
        border-radius: 25px
        box-shadow: $box-shadow
        min-height: 300px // To avoid big death spaces when we have a large difference in content between cards

        > p
          font-size: $font-size-14
          line-height: $font-size-18

          &.position
            font-size: $font-size-16
            line-height: $font-size-18
            margin-bottom: 10px

          &.skill
            color: $color-dark-gray

@media only screen and (min-width: $breakpoint-tablet)
  #freelancer-categories.extended
    background-image: url('./home_background_s2.jpg')

@media only screen and (min-width: $breakpoint-small-desktop)
  #freelancer-categories

    &.extended
      background-position: top center

      > h2, > h1.medium
        width: 900px
        margin: 0 auto
        padding-bottom: 92px

    > .categories-container
      display: grid
      grid-template-columns: auto 1fr
      overflow-x: hidden

      > .role
        grid-column: 1
        display: inline-block

        &:first-child
          padding-top: 50px

      > .positions
        display: block
        grid-column: 2

        .slide
          min-height: unset
          height: 390px
          padding: 19px
          overflow-y: auto

@media only screen and (min-width: 1100px)
  #freelancer-categories > .categories-container
    overflow-x: unset
