@import '../../node_modules/nouislider/dist/nouislider.css'
@import '../variables'

.range-picker
  margin: 30px 20px 0 20px

  > .noUi-base
    > .noUi-connects > .noUi-connect
      background-color: $color-purple

    > .noUi-origin > .noUi-handle > .noUi-tooltip
      border: none
      color: $color-purple
      padding: 0