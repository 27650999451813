@import '../variables'

.home-related
  background-image: url('./home_background_s6.jpg')
  background-repeat: no-repeat
  background-position: 80% 50%
  background-size: cover

  > p, > h2, > h1.medium
    max-width: 60%

  > p
    padding-top: 30px

  > .button-group
    display: flex
    flex-direction: column
    max-width: max-content
    margin-top: 50px

    > a
      &:nth-child(n+2)
        margin-top: 6px

      > button
        width: 100%

        > h4
          display: none

        > span
          > p
            font-size: $font-size-15
            font-weight: $font-weight-400

          > .gr-icon
            display: none

@media only screen and (min-width: $breakpoint-small-desktop)
  .home-related
    background-position: top center

    > p, > h2, > h1.medium
      max-width: unset

    > .button-group
      display: grid
      grid-template-columns: 300px 300px 300px
      grid-template-rows: 1fr
      column-gap: 10px

      > a
        &:nth-child(n+2)
          margin-top: 0

        > button
          height: 100%
          width: 300px
          border-radius: 0 0 0 25px
          position: relative
          padding-bottom: 50px

          &.upper-right-radius
            border-radius: 0 25px 0 0

          > h4
            display: block
            color: $color-white
            text-align: left

          > span
            position: absolute
            right: 15px
            bottom: 15px
            display: flex
            justify-content: flex-end
            align-items: center

            > .gr-icon
              display: inline-block
              font-size: 30px
              margin-left: 10px

        &:hover, &.run-animation
          > button > span
            animation: slide 0.5s forwards